import React from "react"
import "./CardGallery.css"
import "./CardGalleryAnimate.css"
import { Card } from "../../models/card"
import MemoCard from "./card/Card"

const propsAreEqual = (prevProps: { cards: Card[] }, nextProps: { cards: Card[] }): boolean =>
  prevProps.cards.length === nextProps.cards.length &&
  JSON.stringify(prevProps.cards) === JSON.stringify(nextProps.cards)

const CardGallery = React.memo((props: { cards: Card[] }) =>
  (<div className="card-gallery">
    {props.cards.map(c => (
      <MemoCard key={c.id} card={c} />
    ))}
  </div>), propsAreEqual)

export default CardGallery