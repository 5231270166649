import React from "react"
import "./BackgroundMovie.css"

const BackgroundMovie = React.memo(
  (props: { children: JSX.Element, video: string }) => (
    <div id="background-movie">
      {props.children}
      <video autoPlay loop muted controls={false} playsInline>
        <source src={props.video} type="video/mp4" />
Your browser does not support the video tag.
      </video >
    </div>
  )
)

export default BackgroundMovie