import React from "react"
import "./Item.css"
import "./ItemAnimate.css"

const propsAreEqual = (prevProps: { label: string, section: string }, nextProps: { label: string, section: string }): boolean =>
  JSON.stringify(prevProps) === JSON.stringify(nextProps)

const Item = React.memo((props: { label: string, section: string, scrollToSection: (sectionName: string) => void }) =>
  (<span
    key={props.label}
    className="menu-item"
    onClick={() => props.scrollToSection(props.section)}
  >
    {props.label}
  </span>), propsAreEqual)

export default Item