import gql from "graphql-tag";
import { Address } from "../models/address";
import { Card } from "../models/card";
import { ContactUs } from "../models/contactus";
import { Employee } from "../models/employee";
import { MenuItem } from "../models/menuitem";
import { WorkForUs, Opportunity } from "../models/workforus";

export const mollywebQuery = (language: string) =>
  gql`
    query {
      allHeroareas(lang: "${language}") {
        edges {
          node {
            tagline
            video {
              ... on _FileLink {
                url
              }
            }
          }
        }
      }
      allMenuitems(lang: "${language}") {
        edges {
          node {
            label
            section
          }
        }
      }
      allEmployees(lang: "${language}", sortBy: priority_ASC) {
        edges {
          node {
            name
            title
            id
            image
            priority
          }
        }
      }
      allWorkforuss(lang: "${language}") {
        edges {
          node {
            name
            image
            opportunities {
              opportunity {
                ... on Opportunity {
                  title
                  tag
                }
              }
            }
          }
        }
      }
      allContactuss(lang:"${language}") {
        edges {
          node {
            name
            address {
              ... on Address {
                companyname
                street
                city
                zipcode
                country
                orgnr
                email
              }
            }
            contactemployee {
              ... on Employee {
                phone
              }
            }
            background
          }
        }
      }
    }
  `

export interface MollywebDomain {
  video: string;
  menuItems: MenuItem[];
  tagline: string;
  address: Address;
  companyName: string;
  cards: Card[];
}

export const domainParser = (domain: any): MollywebDomain => {
  const menuItems: MenuItem[] = domain.allMenuitems.edges.map(
    (e: any) => new MenuItem(e.node.label, e.node.section)
  );

  const address: Address = new Address(
    domain.allContactuss.edges[0].node.address.companyname,
    domain.allContactuss.edges[0].node.address.street,
    domain.allContactuss.edges[0].node.address.city,
    domain.allContactuss.edges[0].node.address.zipcode,
    domain.allContactuss.edges[0].node.address.orgnr,
    domain.allContactuss.edges[0].node.address.email,
    domain.allContactuss.edges[0].node.address.country
  );

  const companyName: string = domain.allContactuss.edges[0].node.address.companyname.slice(
    0,
    -3
  );

  const cards: Card[] = domain.allEmployees.edges.map(
    (e: any) => new Employee(e.node.name, e.node.title, e.node.image.url, e.node.id)
  );
  cards.splice(
    3,
    0,
    new WorkForUs(
      domain.allWorkforuss.edges[0].node.name,
      domain.allWorkforuss.edges[0].node.image.url,
      domain.allWorkforuss.edges[0].node.opportunities.map(
        (o: any) => new Opportunity(o.opportunity.title, o.opportunity.tag)
      )
    )
  );
  cards.push(
    new ContactUs(
      domain.allContactuss.edges[0].node.name,
      address,
      domain.allContactuss.edges[0].node.contactemployee.phone,
      domain.allContactuss.edges[0].node.background.url
    )
  );
  return {
    menuItems: menuItems,
    video: domain.allHeroareas.edges[0].node.video.url,
    tagline: domain.allHeroareas.edges[0].node.tagline,
    address: address,
    companyName: companyName,
    cards: cards
  };
};