import { Card, Row } from "./card"
import { Address } from "./address"
export class ContactUs implements Card {
  readonly rows: Row[]
  readonly decorate: boolean = false
  readonly tall: boolean = false
  readonly id: string = "contactus"

  constructor(
    readonly name: string,
    address: Address,
    phone: string,
    readonly image: string
  ) {
    const streetZip = [
      new Row(
        address.street,
        `https://www.google.se/maps/search/${address.companyName.replace(
          " ",
          "+"
        )}`
      ),
      new Row(`${address.zipcode} ${address.city}`)
    ]

    const withCountry = address.country
      ? streetZip.concat(new Row(address.country))
      : streetZip

    this.rows = [
      new Row(address.companyName),
      new Row(address.email, `mailto:${address.email}`),
      new Row(phone),
      new Row(`Orgnr. ${address.orgnr}`)
    ].concat(withCountry)
  }
}
