import React from "react"
import "./Card.css"
import { Card } from "../../../models/card"

const propsAreEqual = (prevProps: { card: Card }, nextProps: { card: Card }): boolean =>
  JSON.stringify(prevProps.card) === JSON.stringify(nextProps.card)

const tallOrShort = (isTall: boolean) => isTall ? "tall" : "short"

const MemoCard = React.memo((props: { card: Card }) =>
  (
    <div
      className={`card ${tallOrShort(props.card.tall)}`}
      style={
        { backgroundImage: `url('${props.card.image}')` }
      }
      id={props.card.id}
    >
      <div className="content">
        <h2>{props.card.name}</h2>
        {
          props.card.rows.map(r => (
            <div key={r.text} className="row">
              {
                r.href ? (
                  <a href={r.href} target={"_blank"} rel="noopener noreferrer">
                    {r.text}
                  </a>
                ) : (
                    `${r.text}`
                  )
              }
              < br />
            </div>
          ))
        }
      </div>
    </div >
  ), propsAreEqual)

export default MemoCard