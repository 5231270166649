export class Address {
  constructor(
    readonly companyName: string,
    readonly street: string,
    readonly city: string,
    readonly zipcode: string,
    readonly orgnr: string,
    readonly email: string,
    readonly country?: string
  ) {}
}
