import React from 'react';
import { MenuItem } from "../../models/menuitem";
import Item from './item/Item';
import "./Menu.css";
import "./MenuAnimate.css";
import useScrolledPastElem from './useScrolledPastElem';



const Menu =
  (props: {
    items: MenuItem[],
    scrollToSection: (sectionName: string) => void
    setShowArrow: (showArrow: boolean) => void
  }) => {
    const ref = useScrolledPastElem(props.setShowArrow)

    return (
      <menu ref={ref}>
        {props.items.map(item => (
          <Item key={item.label} {...item} scrollToSection={props.scrollToSection}></Item>
        ))
        }
      </menu >
    )
  }

export default Menu