import React, { useState } from "react"
import "./UpArrow.css"
import "./UpArrowAnimate.css"

const shouldAnimate = (animate: boolean) => animate ? "animate" : ""
const shouldHide = (show: boolean) => show ? "" : "hide"

const animateAndScrollToSection = (setAnimate: (animate: boolean) => void, scrollToSection: (sectionName: string, reset: () => void) => void) => () => {
  setAnimate(true)
  const resetAnimationState = () => setAnimate(false)
  scrollToSection("top", resetAnimationState)
}

const UpArrow = (props: { show: boolean, scrollToSection: (sectionName: string, reset: () => void) => void }) => {
  const [animate, setAnimate] = useState(false)
  return (
    <div id="uparrow"
      className={`${shouldHide(props.show)} ${shouldAnimate(animate)}`}
    >
      <img
        src="/assets/img/uparrow.png"
        srcSet="/assets/img/uparrow.png 1x, /assets/img/uparrow@2x.png 2x"
        onClick={animateAndScrollToSection(setAnimate, props.scrollToSection)}
        alt="scroll up arrow"
      />
    </div>
  )
}

export default UpArrow