import React, { useState } from 'react'
import { PrismicLink } from "apollo-link-prismic"
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client'

import './App.css'
import './AppMain.css'
import './AppFooter.css'
import "./AppFooterAnimate.css"
import "./AppHeader.css"
import "./AppHeaderAnimate.css"

import { domainParser, MollywebDomain, mollywebQuery } from '../../client'
import * as O from "fp-ts/lib/Option"
import { pipe } from 'fp-ts/lib/function'
import BackgroundMovie from '../background-movie/BackgroundMovie'
import CardGallery from '../card-gallery/CardGallery'
import Contact from '../contact/Contact'
import Logo from '../logo/Logo'
import Menu from '../menu/Menu'
import Tagline from '../tagline/Tagline'
import UpArrow from '../up-arrow/UpArrow'

const client = new ApolloClient({
  link: PrismicLink({
    uri: "https://mollyware.prismic.io/graphql",
  }),
  cache: new InMemoryCache()
})

const scrollToSection = (sectionName: string, reset: () => void = () => { }) => {
  const section = document.getElementById(sectionName)
  if (section !== null) {
    section.scrollIntoView({ block: "start", behavior: "smooth" })
    window.setTimeout(() => window.setTimeout(reset, 300), 100)
  }
}

const App = () => {
  const { data, error } = useQuery(mollywebQuery("sv-se"), { client: client })
  const [showArrow, setShowArrow] = useState(false)

  const domain: O.Option<MollywebDomain> = pipe(
    O.fromNullable(data),
    O.map(domainParser)
  )

  return pipe(
    domain,
    O.map(d => (
      <hgroup id="top" >
        <header>
          <BackgroundMovie video={d.video}>
            <div id="overlay">
              <Logo />
              <Tagline
                companyName={d.companyName}
                tagline={d.tagline}
              />
            </div>
          </BackgroundMovie>
        </header>
        <main>
          <Menu
            items={d.menuItems}
            scrollToSection={scrollToSection}
            setShowArrow={setShowArrow}
          />
          <CardGallery cards={d.cards} />
        </main>
        <footer>
          <Logo />
          <Contact address={d.address} />
        </footer>
        <UpArrow show={showArrow} scrollToSection={scrollToSection} />
      </hgroup >
    )),
    O.getOrElse(
      () => (!!error ? <hgroup id="top">Failed to load data due to error: {error}</hgroup> : <hgroup id="top" />)
    )
  )
}

export default App
