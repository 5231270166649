import { Card, Row } from "./card"

export class Employee implements Card {
  readonly rows: Row[]
  readonly decorate: boolean = false
  readonly tall: boolean = true

  constructor(
    readonly name: string,
    readonly title: string,
    readonly image: string,
    readonly id?: string
  ) {
    this.rows = [new Row(title)]
    // new Row(phone, `tel:${phone}`),
    // new Row(email, `mailto:${email}`)
  }
}
