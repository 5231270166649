import React from "react"
import "./Contact.css"
import { Address } from "../../models/address"

const propsAreEqual = (prevProps: { address: Address }, nextProps: { address: Address }): boolean =>
  JSON.stringify(prevProps.address) === JSON.stringify(nextProps.address)

const Contact = React.memo((props: { address: Address }) =>
  (
    <div id="contact">
      <address>
        {props.address.companyName}
        <br />
        {props.address.street}
        <br />
        {props.address.zipcode} {props.address.city}
        <br />
        {props.address.orgnr}
        <br />
      </address>
    </div>
  ), propsAreEqual)

export default Contact