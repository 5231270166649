import { Card, Row } from "./card"

export class Opportunity {
  constructor(public title: string, public tag: string) { }
}

export class WorkForUs implements Card {
  readonly rows: Row[]
  readonly decorate: boolean = true
  readonly tall: boolean = false
  readonly id: string = "workforus"

  constructor(
    readonly name: string,
    readonly image: string,
    public opportunities: Opportunity[]
  ) {
    this.rows = opportunities.map(
      o =>
        new Row(
          o.title,
          `mailto:hello@mollyware.se?subject=Arbeta%20hos%20Mollyware%20som%20${encodeURIComponent(
            o.title
          )}`
        )
    )
  }
}
