import React from "react"
import "./Tagline.css"

const Tagline = React.memo(
  (props: {
    companyName: string
    tagline: string
  }) => (
      <div id="tagline">
        <h1>{props.companyName}</h1>
        <h2>{props.tagline}</h2>
      </div>
    )
)

export default Tagline


