import React from "react"
import "./Logo.css"

const Logo = React.memo(() => (
  <picture id="logo">
    <source
      srcSet="/assets/img/mollyware-logo-text.png 1x, /assets/img/mollyware-logo-text@2x.png 2x"
      media="(min-width: 900px)"
    />
    <img
      src="/assets/img/mollyware-logo-mark.png"
      srcSet="/assets/img/mollyware-logo-mark.png 1x, /assets/img/mollyware-logo-mark@2x.png 2x"
      alt="Mollyware logotype"
    />
  </picture>
))

export default Logo