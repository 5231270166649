import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useCallback, useEffect, useState } from "react";

const listener =
  (menu: HTMLMenuElement, setScrolledPastMenu: (isPast: boolean) => void): EventListener =>
    (evt: Event) => {
      evt.preventDefault()
      const rect = menu.getBoundingClientRect()
      const scrolledPastMenu = rect.bottom < 1;
      setScrolledPastMenu(scrolledPastMenu)
    }

const useScrolledPastElem = (scrolledPastCallback: (isPast: boolean) => void) => {
  const [node, setNode] = useState<HTMLMenuElement | null>(null);
  const [scrolledPastMenu, setScrolledPastMenu] = useState(false)
  const scrolledPastMenuChange = useCallback(scrolledPastCallback, [scrolledPastMenu])
  useEffect(() => scrolledPastMenuChange(scrolledPastMenu))

  const ref = useCallback(node => {
    if (node !== null) {
      setNode(node);
    }
  }, [])

  useEffect(pipe(
    O.fromNullable(node),
    O.map(elem => {
      window.addEventListener('scroll', listener(elem, setScrolledPastMenu))
      return elem
    }),
    O.fold(
      () => () => { },
      (elem) => () => window.removeEventListener("scroll", listener(elem, setScrolledPastMenu))
    )), [node])

  return ref;
}

export default useScrolledPastElem