export class Row {
  constructor(readonly text: string, readonly href?: string) { }
}

export interface Card {
  readonly image: string
  readonly name: string
  readonly rows: Row[]
  readonly decorate: boolean
  readonly tall: boolean
  readonly id?: string
}
